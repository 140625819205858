var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              staticStyle: { display: "inline-block" },
              attrs: {
                searchList: _vm.$cbglSbgjjlSearch,
                listQuery: _vm.listQuery,
              },
              on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-container flex-item" },
        [
          _c(
            "el-row",
            { staticStyle: { height: "100%" }, attrs: { gutter: 8 } },
            [
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { span: 4 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "body-small",
                      staticStyle: { height: "100%", overflow: "auto" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("basic-tree", {
                        attrs: { Treedata: _vm.orgsTree, isShowCheck: true },
                        on: {
                          handleNodeClick: _vm.handleNodeClick,
                          getCheckedKeys: _vm.getCheckedKeys,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { span: 20 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bg-white",
                      staticStyle: { height: "100%" },
                      attrs: { id: "table_box_height" },
                    },
                    [
                      _c("basic-table", {
                        attrs: {
                          tableHeight: _vm.tableHeight,
                          tableHeaderList: _vm.$cbglDscjTableHeader,
                          tableData: _vm.tableData,
                          listQuery: _vm.listQuery,
                        },
                        on: {
                          pagination: _vm.handleCurrentChange,
                          tableRowClick: _vm.tableRowClick,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("general-form", {
        attrs: {
          FormDialog: _vm.FormDialog,
          FormList: _vm.FormList,
          title: _vm.addTitle,
          FormData: _vm.addform,
        },
        on: {
          cancelDialog: _vm.cancelDialog,
          confirmDialog: _vm.confirmDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }